import {Component, Input, OnInit} from '@angular/core';
import {Grupos} from '../models/grupos';
import {PosicionService} from '../posiciones/posicion.service';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-detalle-posiciones',
  templateUrl: './detalle-posiciones.page.html',
  styleUrls: ['./detalle-posiciones.page.scss'],
})
export class DetallePosicionesPage implements OnInit {
  @Input()  grupo: Grupos;
  lista$: Observable<any> | undefined;
  constructor(private posicionService: PosicionService) {

  }

  ngOnInit() {
    this.mostraEquipo(this.grupo.id);
  }
  mostraEquipo(id: number) {
    this.lista$ = this.posicionService.getPosiciones(id);
  }

}
