import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Grupos} from "../models/grupos";
import {Posiciones} from "../models/posiciones";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PosicionService {
  private apiURL= environment.apiURL
  constructor(private  httpClient: HttpClient) { }

  getPosiciones(grupoId: any){
       return this.httpClient.get<any>(this.apiURL+'/posiciones?grupo='+grupoId+'&&_sort=ptos:DESC,dg:DESC,gf:DESC');
  }

  getGrupos(torneoId: any): Observable<Grupos[]>{
    return this.httpClient.get<Grupos[]>(this.apiURL+'/grupos?torneo='+torneoId+'&_sort=Abreviatura:ASC');
  }
}
