import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DetallePosicionesPageRoutingModule } from './detalle-posiciones-routing.module';

import { DetallePosicionesPage } from './detalle-posiciones.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DetallePosicionesPageRoutingModule
  ],
  exports: [
    DetallePosicionesPage
  ],
  declarations: [DetallePosicionesPage]
})
export class DetallePosicionesPageModule {}
